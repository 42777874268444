<template>
  <div>
    <v-row class="ma-0">
      <v-col
        class="d-flex flex-column align-center justify-center pa-0"
        cols="12"
        style="background-color: #000"
      >
        <video width="100%" height="830" autoplay controls loop muted playsinline>
          <source src="@/assets/videos/on-end-to-end.mp4" type="video/mp4" />
        </video>
      </v-col>
    </v-row>

    <EndToEndQuote />

    <v-container style="padding-top: 100px; padding-bottom: 156px">
      <LatestProjects />
    </v-container>

    <v-card
      color="black"
      flat
      tile
      style="overflow: hidden; padding-top: 80px; padding-bottom: 80px"
    >
      <ContactUs />
    </v-card>
  </div>
</template>

<script>
import SectionHead from "@/components/SectionHead.vue";
import EndToEndQuote from "@/components/solutions/end-to-end/EndToEndQuote.vue";
import LatestProjects from "@/components/LatestProjects.vue";
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "EndToEnd",
  components: { SectionHead, EndToEndQuote, LatestProjects, ContactUs },
  data: () => ({
    //
  }),
};
</script>
