<template>
  <div style="position: relative; overflow: hidden">
    <v-container style="position: relative; z-index: 2">
      <v-row>
        <v-col cols="12">
          <v-card
            color="transparent"
            flat
            tile
            height="100%"
            style="padding-bottom: 120px"
          >
            <div class="pt-9 pb-3 text-h4 font-weight-semibold">applications</div>

            <v-list color="transparent" flat>
              <v-list-item-group
                aria-label="List of applications"
                role="list"
                style="pointer-events: none !important"
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  inactive
                  role="listitem"
                  style="pointer-events: none !important"
                >
                  <v-list-item-icon>
                    <v-icon size="32"> mdi-square-small </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.toLocaleLowerCase()"
                      class="text-h6"
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.v-list-item {
  min-height: 0;
}

.v-list-item__icon {
  margin: 2px 0;
}

.v-list-item__content {
  padding: 4px 0;
}
</style>

<script>
export default {
  name: "Applications",
  data: () => ({
    items: [
      "Load Shifting",
      "Peaking Capacity",
      "Transmission Congestion",
      "Ancillary Services (Black Start, Frequency Reg., Voltage Reg.)",
      "Uninterruptible Power Supply (UPS)",
      "Grid Power Backup",
      "Microgrid",
      "Renewable Integration",
      "Carbon Footprint Reduction Tracking",
    ],
  }),
};
</script>
