<template>
  <v-card color="primary" flat tile height="100%">
    <v-container class="containerFeature" style="max-width: 600px">
      <v-img
        alt="On.Energy Project"
        class="mb-6"
        height="200px"
        cover
        :src="require(`@/assets/${image}`)"
      />

      <v-divider class="col-12 pa-0" />

      <div class="text-h4 text-md-h4 font-weight-bold black--text mt-4 mb-8">
        {{ title }}
      </div>

      <div class="mt-auto" @click="dialog = true">
        <v-btn
          class="font-weight-bold rounded-0 pl-10 pr-10"
          color="black"
          dark
          elevation="0"
          target="_blank"
          large
        >
          more info
        </v-btn>
      </div>

      <v-dialog v-model="dialog" width="80%">
        <v-card color="black" style="min-height: 600px">
          <v-card-title style="justify-content: flex-end; margin-bottom: 30px">
            <v-btn
              @click="dialog = false"
              class="py-6 rounded-0"
              size="large"
              style="background-color: #fbed1d"
              text
            >
              <v-icon> mdi-close-thick </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="8">
                <div class="text-h3 font-weight-semibold white--text mb-5">
                  {{ title }}
                </div>

                <div class="text-h5 font-weight-semibold white--text mb-5">
                  <span class="text-h5 font-weight-semibold">location: </span>
                  <span class="text-subtitle-1">{{ location }}</span>
                </div>

                <div class="text-h5 font-weight-semibold white--text mb-5">
                  <span class="text-h5 font-weight-semibold">system overview: </span>
                  <br />
                  <ul class="text-subtitle-1 dash">
                    <li>battery energy storage system</li>
                    <li>20 MWh</li>
                    <li>
                      owned and operated by On.Energy ensuring a seamless and sustainable
                      contribution to the region's energy landscape
                    </li>
                  </ul>
                </div>

                <div class="text-h5 font-weight-semibold white--text mb-5">
                  <span class="text-h5 font-weight-semibold">project highlights: </span>
                  <br />
                  <p class="text-subtitle-1 p-0" style="line-height: 150%">
                    {{ highlights.toLocaleLowerCase() }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<style lang="scss">
.tittleFeature {
  @media screen and (max-width: 600px) {
    padding-top: 40px;
    text-align: center;
  }

  @media screen and (min-width: 600px) {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.btn-porfolio {
  border: 2px solid #000;
  padding: 5px 20px;
  border-radius: 20px;
  font-weight: 900;
}

ul.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  line-height: 1.3em !important;
}

ul.dash > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}
</style>

<script>
export default {
  name: "CardFeatured",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    index: {
      type: String,
    },
    title: {
      type: String,
    },
    location: {
      type: String,
    },
    highlights: {
      type: String,
    },
    front: {
      type: Boolean,
    },
    image: {
      type: String,
      default: "elements/hiring.webp",
    },
  },
};
</script>
