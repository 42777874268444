<template>
  <v-container>
    <v-row style="position: relative; z-index: 2">
      <v-col cols="12">
        <div class="text-h4 font-weight-semibold text-center mb-14">
          trusted by industry leaders
        </div>
      </v-col>

      <v-col
        v-for="(item, i) in items"
        :key="i"
        class="d-flex align-center"
        cols="6"
        sm="4"
        md="3"
      >
        <div class="mx-auto" style="max-width: 100%">
          <v-img
            :height="item.height"
            :max-height="item.height"
            :src="item.logo"
            alt="Industry Leaders"
            contain
            max-width="180"
            transition="fade-transition"
            width="100%"
          />
        </div>
      </v-col>

      <v-col class="mt-14" cols="12">
        <div class="text-h5 font-weight-semibold text-center">and many more...</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Clients",
  data: () => ({
    items: [
      {
        logo: require("@/assets/logos/walmart.webp"),
        height: "40",
      },
      {
        logo: require("@/assets/logos/bimbo.webp"),
        height: "60",
      },
      {
        logo: require("@/assets/logos/glencore.webp"),
        height: "40",
      },
      {
        logo: require("@/assets/logos/enelx.webp"),
        height: "30",
      },
      {
        logo: require("@/assets/logos/parque-arauco.webp"),
        height: "40",
      },
      {
        logo: require("@/assets/logos/skysense.webp"),
        height: "60",
      },
      {
        logo: require("@/assets/logos/amresorts.webp"),
        height: "50",
      },
      {
        logo: require("@/assets/logos/inkia.webp"),
        height: "80",
      },
      {
        logo: require("@/assets/logos/sigma.webp"),
        height: "60",
      },
      {
        logo: require("@/assets/logos/axiom.webp"),
        height: "50",
      },
      {
        logo: require("@/assets/logos/quimp.webp"),
        height: "80",
      },
      {
        logo: require("@/assets/logos/vol.webp"),
        height: "70",
      },
    ],
  }),
};
</script>
