<template>
  <v-row>
    <v-col cols="12" class="d-flex flex-column flex-md-row align-start">
      <div class="d-flex flex-column flex-md-row align-md-center">
        <router-link to="/">
          <v-img
            alt="On.Energy"
            class="mb-6 mb-md-0 mr-8"
            contain
            max-height="50"
            max-width="100%"
            src="@/assets/logos/on-energy-dark.svg"
            width="82"
          />
        </router-link>

        <a href="https://cleanpower.org/" target="_blank">
          <v-img
            alt="American Clean Power - Business Member"
            contain
            max-height="50"
            max-width="100%"
            src="@/assets/logos/american-clean-power.webp"
            transition="fade-transition"
            width="172"
          />
        </a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Links",
  data: () => ({
    //
  }),
};
</script>
