const sendMessageApi = (data) => {

    const url = 'https://a9jjlw6f6b.execute-api.us-east-2.amazonaws.com/production/contactForm';
    const params = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    };

    return fetch(url, params);
}

export default sendMessageApi;