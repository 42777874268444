<template>
  <div>
    <SectionHead
      title="projects"
      text="successfully and safely deployed projects for the americas"
      image="hero/projects.webp"
    />

    <Featured />

    <v-card class="software" color="black" flat tile>
      <Map />
    </v-card>

    <FeaturedPaloDeAgua />

    <v-container style="padding-top: 160px; padding-bottom: 160px">
      <ProjectsOperational />
    </v-container>

    <v-card
      color="black"
      flat
      tile
      style="overflow: hidden; padding-top: 80px; padding-bottom: 80px"
    >
      <ContactUs />
    </v-card>
  </div>
</template>

<script>
import SectionHead from "@/components/SectionHead.vue";
import Featured from "@/components/projects/Featured.vue";
import Map from "@/components/projects/Map.vue";
import FeaturedPaloDeAgua from "@/components/projects/FeaturedPaloDeAgua.vue";
import ProjectsOperational from "@/components/projects/ProjectsOperational.vue";
import Card from "@/components/projects/Card.vue";
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "Projects",
  components: {
    SectionHead,
    Featured,
    Map,
    FeaturedPaloDeAgua,
    ProjectsOperational,
    Card,
    ContactUs,
  },
};
</script>
