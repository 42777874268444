import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import EndToEnd from "../views/Solutions/EndToEnd.vue";
import ViewSoftware from "../views/Solutions/ViewSoftware.vue";
import ViewProjects from "../views/ViewProjects.vue";
import SingleProject from "../views/Projects/SingleProject.vue";
import Team from "../views/Team.vue";
import Contact from "../views/Contact.vue";
import ThirdPartyLicenses from "../views/ThirdPartyLicenses.vue";
import PageNotFound from "../views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/solutions",
    name: "Solutions",
    component: EndToEnd,
  },
  {
    path: "/solutions/end-to-end",
    name: "EndToEnd",
    component: EndToEnd,
  },
  {
    path: "/solutions/software",
    name: "Software",
    component: ViewSoftware,
  },
  {
    path: "/projects",
    name: "Projects",
    component: ViewProjects,
  },
  {
    path: "/projects/singleproject",
    name: "SingleProject",
    component: SingleProject,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/third-party-licenses",
    name: "ThirdPartyLicenses",
    component: ThirdPartyLicenses,
  },
  {
    path: "/shop",
    beforeEnter() {
      window.location.href = "https://energytransitionnow.myshopify.com/#login";
    },
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
