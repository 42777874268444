<template>
  <v-container class="py-8 py-md-3">
    <v-row>
      <v-col cols="12">
        <div
          class="mx-auto text-h2 font-weight-semibold text-center mb-8"
          style="max-width: 800px"
        >
          battery storage ipp
        </div>

        <div
          class="mx-auto text-h4 font-weight-semibold text-center"
          style="max-width: 800px"
        >
          we design, build and operate energy storage solutions to drive forward a low
          carbon future
        </div>
      </v-col>

      <v-col
        v-for="(item, i) in items"
        :key="i"
        class="d-flex flex-wrap py-16"
        :class="item.class"
        cols="12"
        sm="6"
        md="3"
      >
        <div
          v-text="item.title"
          class="col-12 pa-0 pb-3 text-h6 font-weight-semibold black--text text-center"
        />

        <v-divider class="col-12" />

        <div
          v-text="item.kpi"
          class="d-flex flex-nowrap align-baseline justify-center kpi col-12 pa-0 text-h3 font-weight-semibold black--text text-center"
        />
      </v-col>

      <v-col
        class="d-flex flex-column justify-center flex-sm-row align-sm-center pt-12 pb-16"
      >
        <v-btn
          to="/solutions/end-to-end"
          class="font-weight-bold primary--text mb-6 mb-sm-0 mr-sm-12 px-16 py-8 rounded-0"
          color="black"
          elevation="0"
          x-large
        >
          solutions
        </v-btn>

        <v-btn
          to="/projects"
          class="font-weight-bold primary--text ml-sm-12 px-16 py-8 rounded-0"
          color="black"
          elevation="0"
          x-large
        >
          projects
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.add-hours {
  .kpi {
    &::after {
      content: "hours";
      font-size: 24px;
      line-height: 1;
      margin-left: 12px;
    }
  }
}
</style>

<script>
export default {
  name: "HomeKpis",
  data: () => ({
    items: [
      {
        title: "in operation",
        kpi: "80 Mwh",
      },
      {
        title: "in construction",
        kpi: "400 Mwh",
      },
      {
        title: "in development",
        kpi: "3 Gwh",
      },
      {
        title: "projects constructed",
        kpi: "50+",
      },
    ],
  }),
};
</script>
