<template>
  <v-card
    class="flex d-flex flex-column"
    :color="background ? 'primary' : 'black'"
    @click="dialog = true"
  >
    <div class="text-center justify-center" style="height: 100%">
      <v-card :color="background ? 'primary' : 'black'" flat tile>
        <v-row style="padding: 0; margin: 0; height: 100%">
          <v-col md="2" />

          <v-col md="8">
            <div style="width: 100%; display: flex">
              <v-img
                alt="On.Energy Project"
                class="mb-3"
                contain
                height="360"
                :src="require(`@/assets/${picture}`)"
                width="100%"
              />
            </div>

            <div
              class="text-h5 font-weight-semibold"
              :class="background ? 'black--text' : 'primary--text'"
            >
              {{ teamMember }}
            </div>

            <div
              class="text-subtitle-1"
              :class="background ? 'black--text' : 'white--text'"
              style="padding-bottom: 30px"
            >
              {{ position }}
            </div>
          </v-col>

          <v-col md="2" />
        </v-row>
      </v-card>
    </div>

    <v-dialog v-model="dialog" width="80%">
      <v-card
        style="background-color: #000; min-height: 600px"
        :color="background ? 'primary' : 'black'"
      >
        <v-card-title style="justify-content: flex-end; margin-bottom: 30px">
          <v-btn
            :color="background ? 'black' : 'primary'"
            @click="dialog = false"
            class="py-6 rounded-0"
            size="large"
            text
          >
            <v-icon> mdi-close-thick </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row class="align-center">
            <v-col cols="0" md="1" />

            <v-col
              cols="12"
              md="4"
              style="display: flex; flex-direction: column; align-items: center"
            >
              <div style="display: flex; align-items: center">
                <v-img
                  alt="On.Energy Team"
                  height="auto"
                  max-width="100%"
                  :src="require(`@/assets/${picture}`)"
                  width="300px"
                />
              </div>

              <div
                class="text-h5 font-weight-semibold text-center"
                v-bind:class="background ? 'black--text' : 'primary--text'"
              >
                {{ teamMember }}
              </div>

              <div
                class="text-subtitle-1 text-center"
                v-bind:class="background ? 'black--text' : 'white--text'"
              >
                {{ position }}
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div v-bind:class="background ? 'black--text' : 'white--text'">
                <div v-html="resume" class="text-body-1" />
              </div>

              <div class="mt-6" style="align-items: center; display: flex; width: 100%">
                <a :href="linkedin" class="d-flex text-decoration-none" target="_blank">
                  <v-img
                    alt="Linkedin"
                    class="mr-3"
                    contain
                    height="24px"
                    src="@/assets/logos/linkedin-dark.svg"
                    transition="fade-transition"
                    width="24px"
                    v-if="background"
                  />

                  <v-img
                    alt="Linkedin"
                    class="mr-3"
                    contain
                    height="24px"
                    src="@/assets/logos/linkedin-light.svg"
                    transition="fade-transition"
                    width="24px"
                    v-if="!background"
                  />

                  <span
                    class="font-weight-light text-body-1 text-lowercase"
                    v-bind:class="background ? 'black--text' : 'white--text'"
                  >
                    Linkedin Profile
                  </span>
                </a>
              </div>
            </v-col>

            <v-col md="1" />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "CardTeam",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    teamMember: {
      type: String,
    },
    picture: {
      type: String,
    },
    position: {
      type: String,
    },
    background: {
      type: Boolean,
    },
    resume: {
      type: String,
    },
    linkedin: {
      type: String,
    },
  },
};
</script>
