import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Carousel3d from 'vue-carousel-3d';
import VueGtag from 'vue-gtag';

Vue.use(Carousel3d);

Vue.config.productionTip = false;
let useAnalytics = (process.env.VUE_APP_ENABLED_ANALYTICS === 'true') && (process.env.VUE_APP_ANALYTICS_ID !== '');

Vue.use(
	VueGtag,
	{
		config: {
			id: process.env.VUE_APP_ANALYTICS_ID,
			disableScriptLoad: !useAnalytics,
			enabled: useAnalytics,
		},
	},
	router
);


new Vue({
	router,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
