<template>
  <v-card class="kpis pt-10" color="primary" flat tile style="padding-bottom: 160px">
    <v-container class="py-8 py-md-3">
      <v-row>
        <v-col
          color="primary"
          class="text-alt text-caption font-weight-black text-center text-md-right text-lowercase"
          flat
          cols="12"
        >
          highlighted under contruction projects
        </v-col>
      </v-row>

      <v-row>
        <v-col color="primary" flat cols="12">
          <v-col class="d-flex align-center flex-column text-center" cols="12">
            <v-img
              alt="Palo de Agua"
              contain
              height="192"
              max-width="100%"
              src="@/assets/logos/palo-agua.webp"
              transition="fade-transition"
              width="400"
            />
          </v-col>

          <v-col class="d-flex align-center flex-column text-center" cols="12">
            <span class="btn-porfolio text-body-2 font-weight-bold">
              clean energy center
            </span>
          </v-col>

          <v-col class="d-flex align-center flex-column text-center" cols="12">
            <span class="text-h5 v-application text-weight-bold">Texas, USA</span>
          </v-col>

          <v-col
            color="primary"
            class="font-weight-black text-caption text-alt text-center mb-6"
            cols="12"
          >
            Will be Operational in 2025
          </v-col>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-bind:key="item.id"
          v-for="(item, i) in orderedItems"
          class="pa-0"
          cols="12"
          :index="i"
        >
          <ListFeatured :index="item.index" :title="item.title" :size="item.size" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style lang="scss">
.btn-porfolio {
  border: 2px solid #000;
  padding: 5px 20px;
  border-radius: 20px;
  font-weight: 900;
}
</style>

<script>
import ListFeatured from "@/components/projects/ListFeatured.vue";

export default {
  name: "FeaturedPaloDeAgua",
  components: { ListFeatured },
  data: () => ({
    items: [
      {
        index: "01",
        title: "alamo",
        size: "9.9MW 20MWh",
      },
      {
        index: "02",
        title: "ft stockton",
        size: "9.9MW 20MWh",
      },
      {
        index: "03",
        title: "mesquite",
        size: "9.9MW 20MWh",
      },
      {
        index: "04",
        title: "north pecos",
        size: "9.9MW 20MWh",
      },
      {
        index: "05",
        title: "colorado 1",
        size: "9.9MW 20MWh",
      },
      {
        index: "06",
        title: "colorado",
        size: "9.9MW 20MWh",
      },
      {
        index: "07",
        title: "andrews",
        size: "9.9MW 20MWh",
      },
      {
        index: "08",
        title: "matagorda 1",
        size: "9.9MW 20MWh",
      },
      {
        index: "09",
        title: "matagorda 2",
        size: "9.9MW 20MWh",
      },
      {
        index: "10",
        title: "badger",
        size: "9.9MW 20MWh",
      },
    ],
  }),
  computed: {
    orderedItems: function () {
      return _.orderBy(this.items, "index", "asc");
    },
  },
};
</script>
