<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center mt-16 pt-16 mb-6" cols="12">
        <v-img
          alt="On.Energy"
          contain
          src="@/assets/elements/spring.webp"
          height="100"
          max-width="200"
          transition="fade-transition"
          width="100%"
        />
      </v-col>

      <v-col cols="12">
        <div
          class="mx-auto text-h4 font-weight-semibold text-center"
          style="max-width: 960px"
        >
          On.Energy's energy management system (EMS) monitors, controls and dispatches
          energy storage systems to improve the way energy is being used
        </div>
      </v-col>

      <v-col cols="12" md="5">
        <v-card
          class="d-flex align-center flex-column justify-content-center"
          color="transparent"
          flat
          tile
          height="100%"
          style="padding-top: 120px; padding-bottom: 120px"
        >
          <v-img
            alt="On.Command™"
            contain
            max-width="440"
            src="@/assets/logos/on-command.webp"
            transition="fade-transition"
            width="100%"
          />

          <v-img
            alt="On.Command™"
            contain
            src="@/assets/elements/mockup.webp"
            class="monitorBackground"
            transition="fade-transition"
          />
        </v-card>
      </v-col>

      <v-spacer />

      <v-col cols="12" md="6">
        <v-card
          class="mt-md-16 pt-md-16 px-md-5"
          color="transparent"
          flat
          tile
          height="100%"
        >
          <div class="pb-3 text-h6 text-alt grey--text text--darken-1">software</div>

          <v-divider class="col-12 pa-0" />

          <div class="py-9 text-h4 font-weight-semibold black--text">
            hardware agnostic
          </div>

          <div class="pb-9 text-h6 black--text">
            On.Energy's On.Command™ enables the integration of different technologies,
            vendors and distributed energy resources
          </div>

          <div class="py-9 text-h4 font-weight-semibold black--text">ai-driven</div>

          <div class="pb-9 text-h6 black--text">
            On.Energy has developed proprietary algorithms using machine-learning that
            predict customer consumption and grid performance
          </div>

          <div class="py-9 text-h4 font-weight-semibold black--text">
            remote monitoring
          </div>

          <div class="pb-9 text-h6 black--text">
            On.Energy provides real time remote monitoring of operations for optimal
            performance, predictive maintenance and warranty tracking
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.monitorBackground {
  @media screen and (max-width: 600px) {
    max-width: 360px;
  }

  @media screen and (min-width: 600px) {
    max-width: 560px;
  }
}
</style>

<script>
export default {
  name: "SoftwareWelcome",
  data: () => ({
    //
  }),
};
</script>
