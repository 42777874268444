<template>
  <v-container class="py-8 py-md-3">
    <v-row>
      <v-col
        v-for="(item, i) in items"
        :key="i"
        class="d-flex flex-wrap py-16"
        :class="item.class"
        cols="12"
        sm="6"
        md="3"
      >
        <div
          v-text="item.title"
          class="col-12 pa-0 pb-3 text-h6 font-weight-semibold text-center"
        />

        <v-divider class="col-12" />

        <div
          v-text="item.kpi"
          class="d-flex flex-nowrap align-baseline justify-center kpi col-12 pa-0 text-h2 font-weight-semibold text-center"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.add-hours {
  .kpi {
    &::after {
      content: "hours";
      font-size: 24px;
      line-height: 1;
      margin-left: 12px;
    }
  }
}
</style>

<script>
export default {
  name: "Kpis",
  data: () => ({
    items: [
      {
        title: "ems combined runtime",
        kpi: "800K+",
        class: "add-hours",
      },
      {
        title: "applications",
        kpi: "15+",
      },
      {
        title: "proprietary software",
        kpi: "100%",
      },
      {
        title: "remote monitoring",
        kpi: "24/7",
      },
    ],
  }),
};
</script>
