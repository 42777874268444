<template>
  <v-row class="py-16">
    <v-col cols="12">
      <v-col cols="12">
        <v-row style="position: relative">
          <v-col class="d-flex flex-column flex-wrap" cols="12">
            <div class="text-h3 font-weight-bold text--underline">latest projects</div>
          </v-col>
        </v-row>
      </v-col>

      <v-row style="position: relative">
        <v-col cols="12" md="4">
          <Card
            class="px-3"
            title="maverick - palo verde portfolio"
            image="projects/maverick-2.webp"
            application="10MW - 20MWh"
            details="operating in the ERCOT grid"
            location="alvin, texas"
            :front="true"
          />
        </v-col>

        <v-col cols="12" md="4">
          <Card
            class="px-3"
            title="crosby - palo verde portfolio"
            image="projects/cougar-2.webp"
            application="10MW - 20MWh"
            details="operating in the ERCOT grid"
            location="crosby, texas"
            :front="true"
          />
        </v-col>

        <v-col cols="12" md="4">
          <Card
            class="px-3"
            title="spencer - palo verde portfolio"
            image="projects/spencer-2.webp"
            application="10MW - 20MWh"
            details="operating in the ERCOT grid"
            :front="true"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col class="d-flex justify-center" cols="12">
      <v-btn
        to="/projects"
        class="font-weight-bold black--text mt-6 px-16 py-8 rounded-0"
        color="primary"
        elevation="0"
        x-large
      >
        more projects
      </v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.carousel-3d-slide {
  background-color: white;
  opacity: 0 !important;
  z-index: 970 !important;

  &.current {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.right-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }

  &.left-1 {
    opacity: 1 !important;
    z-index: 980 !important;
  }
}
</style>

<script>
import Card from "@/components/projects/Card.vue";

export default {
  name: "LatestProjects",
  components: {
    Card,
  },
  data: () => ({
    //
  }),
  methods: {
    goNext() {
      this.$refs.mycarousel.goNext();
    },
    goPrev() {
      this.$refs.mycarousel.goPrev();
    },
  },
};
</script>
